import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-info',
  templateUrl: 'info.component.html'
})
export class InfoComponent {
    @Input() item: any;
    @Input() layout: number;
}
