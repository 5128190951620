import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-text',
  templateUrl: 'text.component.html'
})
export class TextComponent {
    @Input() item: any;
    @Input() layout: number;
}
