import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-helpdesk',
  templateUrl: 'helpdesk.component.html'
})
export class HelpdeskComponent implements OnChanges {
  @Input() item: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  constructor(
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.item) {
      if (!this.item.helpdeskPage) {
        this.item.helpdeskPage = {
          title1: {
            text: 'Fale Conosco do',
            color: '#000000',
            size: 36
          },
          title2: {
            text: 'seu Portal',
            color: '#666666',
            size: 36
          },
          subTitle: {
            text: 'Tire sua dúvidas, envie sugestões ou elogio para a nossa equipe.',
            color: '#aaaaaa',
            size: 18
          }
        }
      }
    }
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

}
