import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-terms',
  templateUrl: 'terms.component.html'
})
export class TermsComponent implements OnInit {
  @Input() item: any;
  @Input() layout: number;

  constructor(
  ) {
  }

  ngOnInit() {

  }

}
