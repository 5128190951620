import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-portal',
  templateUrl: 'portal.component.html'
})
export class PortalComponent {
  @Input() item: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  constructor(
  ) {
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  getPhoto(target: any, $event: any): void {
    if (target === 'logoLight') {
      this.item.logoLight = $event.path;
    }
    if (target === 'logoDark') {
      this.item.logoDark = $event.path;
    }
    if (target === 'logoFooter') {
      this.item.logoFooter = $event.path;
    }
    if (target === 'logoEAD') {
      this.item.logoEAD = $event.path;
    }
    if (target === 'logoHelpdesk') {
      this.item.logoHelpdesk = $event.path;
    }
    if (target === 'emblem') {
      this.item.emblem = $event.path;
    }

  }
}
