export class Field {
  id: number;
  name: string;
  label: string;
  doc_type: string;
  type: string;
  published: number;
  min?: number;
  max?: number;
  required?: number;
  ordering?: number;
  fieldCode?: string;
  hide_cep?: boolean;
  options?: any;
  memberOf?: Array<number>;
  select_filter: number;
  tips: string;
  width: string;
  address_fields: any;
  valid?: Array<any>;
  interval_text?: string;
  state: string;
}
