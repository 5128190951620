import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-terms-config',
  templateUrl: 'terms-config.component.html'
})
export class TermsConfigComponent {
  @Input() item: any;
  @Input() layout: number;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  termStyle: string;

  constructor(
  ) {
  }

  ngOnInit() {
    console.log('ITEM ->', this.item);
    if (Array.isArray(this.item.url)) {
      if (this.item.url.length <= 0) {
        this.item.url = [];
      }
    } else {
      this.item.url = [];
    }

    if (typeof this.item.termStyle === 'undefined') {
      this.item.termStyle = 'privacy';
    }
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  getPhoto($event: any): void {
    this.item.favicon = $event.path;
  }

}
