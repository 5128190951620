import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ClientService } from 'src/app/@core/services/client.service';
import { LayoutService } from 'src/app/@core/services/layout.service';

@Component({
  selector: 'app-editor-custom-component-cor-config',
  templateUrl: 'cor-config.component.html'
})
export class CorConfigComponent {
  @Input() item: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  colorToAdd: string;

  themeStyle: string;

  layout;
  componentConfig;

  acceptTheme = false;

  themeColors = {
    'bg-dark': '#111111',
    'bg-dark-2': '#333333',
    'main-color-dark': '#dddddd',
    'bg-sidebar-viewer-dark': '#181818',
    'bg-btn-sidebar-viewer-dark-active': '#ddd',
    'bg-btn-sidebar-viewer-text-dark-active': '#111111',
    'icon-sidebar-viewer-dark': '#ddd',
    'link-sidebar-viewer-dark': '#ddd',
    'bg-light': '#f4f4f4',
    'bg-light-2': '#eee',
    'main-color-light': '#414141',
    'bg-sidebar-viewer-light': '#fff',
    'bg-btn-sidebar-viewer-light-active': '#414141',
    'bg-btn-sidebar-viewer-text-light-active': '#f4f4f4',
    'icon-sidebar-viewer-light': '#414141',
    'link-sidebar-viewer-light': '#414141'
  };

  constructor(
    public clientService: ClientService,
    public layoutService: LayoutService
  ) {
  }

  ngOnInit () {
    if (typeof this.item.themeStyle === 'undefined') {
      this.item.themeStyle = 'light';
    }
  }

  emitSendEvent() {
    this.sendEvent.emit();
    if (this.componentConfig) {
        this.layoutService.createResource({ value: this.layout }).subscribe((res: any) => {

        });
    }
  }

  getPhotoWithTarget(target: any, $event: any): void {
    this.item[target] = $event.path;
  }

  setAlign(element: any, align: string): void {
    element.align = align;
  }

  addColorToPallet() {
    if (this.colorToAdd === '') {
      return;
    }

    if (!this.item.myColors) {
      this.item.myColors = [];
    }
    this.item.myColors.push(this.colorToAdd);
    this.colorToAdd = '';
  }

  removeColor(index: number) {
    this.item.myColors.splice(index, 1);
  }

  selectStyle(theme) {
    if (theme == 'light') {
      this.item.bgClient = this.themeColors['bg-light'];
      this.item.mainColor = this.themeColors['main-color-light'];
      this.item.primaryColor = this.themeColors['main-color-light'];
      this.item.primaryColorHover = this.themeColors['main-color-light'];
      this.item.textColor = this.themeColors['bg-btn-sidebar-viewer-text-light-active'];
      this.item.bgNavbar = this.themeColors['bg-sidebar-viewer-light'];
      this.item.linkNavbar = this.themeColors['link-sidebar-viewer-light'];
      this.item.bgSidebar = this.themeColors['bg-sidebar-viewer-light'];
      this.item.linkSidebar = this.themeColors['link-sidebar-viewer-light'];
      this.item.bgFooter = this.themeColors['bg-sidebar-viewer-light'];
      this.item.linkFooter = this.themeColors['link-sidebar-viewer-light'];
      this.item.bgBtn = this.themeColors['bg-btn-sidebar-viewer-light-active'];
      this.item.textBtn = this.themeColors['bg-btn-sidebar-viewer-text-light-active'];

      this.item.cardBackground = this.themeColors['bg-sidebar-viewer-light'];
      this.item.contentTitleColor = this.themeColors['link-sidebar-viewer-light'];
      this.item.contentSubTitleColor = this.themeColors['link-sidebar-viewer-light'];

      this.item.bgSidebarViewer = this.themeColors['bg-sidebar-viewer-light'];
      this.item.bgBtn = this.themeColors['bg-btn-sidebar-viewer-light-active'];
      this.item.textBtn = this.themeColors['bg-btn-sidebar-viewer-text-light-active'];
      this.item.bgBtnSidebarViewerActive = this.themeColors['bg-btn-sidebar-viewer-light-active'];
      this.item.bgBtnSidebarViewerTextActive = this.themeColors['bg-btn-sidebar-viewer-text-light-active'];
      this.item.iconSidebarViewer = this.themeColors['icon-sidebar-viewer-light'];
      this.item.linkSidebarViewer = this.themeColors['link-sidebar-viewer-light'];
    }
    else if (theme == 'dark') {
      this.item.bgClient = this.themeColors['bg-dark'];
      this.item.mainColor = this.themeColors['main-color-dark'];
      this.item.primaryColor = this.themeColors['main-color-dark'];
      this.item.primaryColorHover = this.themeColors['main-color-dark'];
      this.item.textColor = this.themeColors['bg-btn-sidebar-viewer-text-dark-active'];
      this.item.bgNavbar = this.themeColors['bg-sidebar-viewer-dark'];
      this.item.linkNavbar = this.themeColors['link-sidebar-viewer-dark'];
      this.item.bgSidebar = this.themeColors['bg-sidebar-viewer-dark'];
      this.item.linkSidebar = this.themeColors['link-sidebar-viewer-dark'];
      this.item.bgFooter = this.themeColors['bg-sidebar-viewer-dark'];
      this.item.linkFooter = this.themeColors['link-sidebar-viewer-dark'];
      this.item.bgBtn = this.themeColors['bg-btn-sidebar-viewer-dark-active'];
      this.item.textBtn = this.themeColors['bg-btn-sidebar-viewer-text-dark-active'];

      this.item.bgSidebarViewer = this.themeColors['bg-sidebar-viewer-dark'];
      this.item.bgBtn = this.themeColors['bg-btn-sidebar-viewer-dark-active'];
      this.item.textBtn = this.themeColors['bg-btn-sidebar-viewer-text-dark-active'];
      this.item.bgBtnSidebarViewerActive = this.themeColors['bg-btn-sidebar-viewer-dark-active'];
      this.item.bgBtnSidebarViewerTextActive = this.themeColors['bg-btn-sidebar-viewer-text-dark-active'];
      this.item.iconSidebarViewer = this.themeColors['icon-sidebar-viewer-dark'];
      this.item.linkSidebarViewer = this.themeColors['link-sidebar-viewer-dark'];
    }
    
    this.configureComponents(theme);
  }

  configureComponents(theme, component = null) {
    if (Array.isArray(component)) {
      for (const c of component) {
        if (c) {
          this.configureComponents(theme, c);
        }
      }
    }
    else if (component) {
      const backFields = ['background', 'cardButtonBackground', 'cardForegroundBottom', 'cardForegroundTop', 'buttonBackground'];
      const cardBackFields = ['cardBackground'];
      const faceFields = ['contentKeywordColor', 'color', 'cardColor', 'cardButtonColor', 'contentResumeColor', 'contentSubtitleColor', 'contentTitleColor', 'dateColor', 'buttonColor', 'subtitleColor', 'titleColor'];
      for (const key in component) {
        if (backFields.includes(key)) {
          component[key] = this.themeColors[`bg-${theme}`];
        }
        else if (cardBackFields.includes(key)) {
          component[key] = this.themeColors[`bg-sidebar-viewer-${theme}`];
        }
        else if (faceFields.includes(key)) {
          component[key] = this.themeColors[`link-sidebar-viewer-${theme}`];
        }
        else if ((component[key]) && (typeof component[key] === 'object')) {
          this.configureComponents(theme, component[key]);
        }
      }
    }
    else {
      if (this.componentConfig) {
        this.configureComponents(theme, this.componentConfig);
      }
      else {
        this.clientService.getColor(this.clientService.client).subscribe((response) => {
          console.log('[configureComponents]', response);
          this.layout = response.data.layout[0].value;
          this.componentConfig = response.data.layout[0].value;
          this.configureComponents(theme, this.componentConfig);
        });
      }
    }
  }

}
