import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-helpdesk-config',
  templateUrl: 'helpdesk-config.component.html'
})
export class HelpdeskConfigComponent {
  @Input() item: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  colorToAdd: string;

  constructor(
  ) {
  }

  formatLabel(value: number) {
    return `${value}px`;
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

}
