import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-editor-custom-component-pre-viewer',
  templateUrl: 'pre-viewer.component.html',
  styleUrls: ['./pre-viewer.component.scss']
})
export class PreViewerComponent {
  @Input() item: any;

  public journeys = [
    { img: 'http://res.cloudinary.com/dxo3njt4e/image/upload/v1658846410/otzaimns4xe4sn5hut6g.webp', title: 'Deenvolvimento web avançado', description: ' Bem vindo ao Desenvolvimento Web Avançado 2022 com PHP, Laravel'},
    { img: 'http://res.cloudinary.com/dxo3njt4e/image/upload/v1653408396/pmexnubxvvbs9m92svbw.webp', title: 'Java script', description: ' Conecte os conhecimentos da JavaScript, HTML e CSS para construir páginas web elegantes e funcionais'},
    { img: 'http://res.cloudinary.com/dxo3njt4e/image/upload/v1653408428/jltgvhfi8z7m0cpxflzd.webp', title: 'Phyton', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
    { img: 'http://res.cloudinary.com/dxo3njt4e/image/upload/v1653408465/iagxzprpivuwztfckz1t.webp', title: 'Photoshop', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
    { img: 'http://res.cloudinary.com/dxo3njt4e/image/upload/v1653408499/hp31ybbe3bbwvmuffka9.webp', title: 'HTML 5', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
    { img: 'http://res.cloudinary.com/dxo3njt4e/image/upload/v1653408499/hp31ybbe3bbwvmuffka9.webp', title: 'CSS 3', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'},
  ];

  public mockup = [
    { stamp: 'img' },
    { stamp: 'pdf' },
    { stamp: 'txt' },
    { stamp: 'video' },
  ];

  owlOptions = {
    loop: true,
    dots: false,
    nav: false,
    autoplay: false,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    center: true,
    margin: 15,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 5,
      },
    },
  };
}
