import { Component, Input } from '@angular/core';
import { Global } from 'asap-crud';

@Component({
    selector: 'app-editor-custom-component-profile-about',
    templateUrl: 'profile-about.component.html',
    styleUrls: [ './profile-about.component.scss' ]
})
export class ProfileAboutComponent {

    @Input() item: any;
    @Input() layout: number;

    public selectedField;
    public user;

    constructor(
        public global: Global
    ) {
        this.user = this.global.loggedUser();
    }


}
