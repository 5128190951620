import { Component, OnInit, ViewChild } from '@angular/core';
import { ClientService } from '../@core/services/client.service';
import { LayoutService } from '../@core/services/layout.service';
import Swal from 'sweetalert2';

import { DataService } from '../@core/services/data.service';
import { AsapClientPipe } from '../@core/pipes/asap-client.pipe';
import { GroupRepository } from '../@core/repository/group.repository';
import { Group } from '../@core/model/group.model';

import { MatStepperModule } from '@angular/material/stepper';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})

export class HomeComponent implements OnInit {
  @ViewChild('headerComponent', { static: false }) headerComponent: any;
  @ViewChild('supplementaryDataComponent', { static: false }) supplementaryDataComponent: any;
  @ViewChild('colRight', { static: false }) colRight: any;

  id: number;
  item: any;
  trailStageSelected: any;
  settings: any;
  layout: number;
  styleZoom: number;

  customData: any;

  data: any;

  public componentArray: Array<any>;
  public sidebarComponentArray: Array<any>;
  public fullComponentArray: Array<any>;
  public topComponentArray: Array<any>;

  public itemSelected: any;
  public status: string;
  componentLoading = true;

  section = 'layout';

  layoutComponentSelected: string;

  groupArray: Array<Group>;
  filterGroup: Group;

  constructor(
    private dataService: DataService,
    public groupRepository: GroupRepository,
    private clientService: ClientService
  ) {
    this.customData = {
      primaryColor: '#333333',
      secondaryColor: '#333333',
      greyColor: '#AAAAAA'
    };
    this.layoutComponentSelected = 'info';
  }

  ngOnInit() {
    this.status = '';
    this.getColor();
    this.getGroups();
  }

  getCustomization() {
    this.clientService.getClientData().subscribe((response: any) => {
      this.layout = response.data.layout;
      this.getColor();
    });
  }

  getColor() {
    this.clientService.getColor()
    .subscribe((res: any) => {

      if (!res[0].config) {
        this.settings = {};
      } else if (res[0].config.url) {
        this.settings = res[0].config;
      } else {
        this.settings = res[0].config;
      }

      AsapClientPipe.setDictionary(this.settings);

      this.settings = AsapClientPipe.dictionary;

      console.log('AsapClientPipe.dictionary', AsapClientPipe.dictionary);

      this.setHtmlData();

      this.customData = {
        primaryColor: this.settings.mainColor,
        secondaryColor: this.settings.bgBtn,
        greyColor: '#AAAAAA'
      };

      // if ([1, 4, 5, 6].indexOf(this.layout) >= 0) {
      //   if ((!this.settings.firebase) || (Array.isArray(this.settings.firebase))) {
      //     this.settings.firebase = {};
      //   }
      // }

      this.componentLoading = false;
    });
  }

  saveConfig() {
    Swal.fire('Sucesso', 'Personalização salva com sucesso', 'success');

    console.log(this.settings);
    this.settings.loginMobileButtonText = (this.settings.loginMobileButtonText === '') ? 'Entrar' : this.settings.loginMobileButtonText;

    this.clientService.putColor(this.settings)
      .subscribe((res: any) => {
        console.log(res);
      });
  }

  selectLayoutItem($event: any): void {
    this.layoutComponentSelected = $event;
  }

  reloadStage() {
    this.supplementaryDataComponent.reloadFields();
  }

  getGroups() {
    this.groupRepository.collection().then(res => {
      this.groupArray = res;
    });
  }

  removeFilterFieldsByGroup() {
    this.filterGroup = null;

    this.supplementaryDataComponent.groupFilter = null;
    this.supplementaryDataComponent.groupFilterId = '0';
    this.supplementaryDataComponent.fieldFilterApply();

    this.colRight.filterByGroupApply(false);
  }

  filterFieldsByGroup(group: Group) {
    this.filterGroup = group;
    this.supplementaryDataComponent.groupFilter = group;
    this.supplementaryDataComponent.groupFilterId = group.id;
    this.supplementaryDataComponent.fieldFilterApply();

    this.colRight.filterByGroupApply(group);
  }

  setHtmlData() {
    const html = document.querySelector('html');

    html.style.setProperty('--bg-client', AsapClientPipe.dictionary.bgClient);

    html.style.setProperty('--main-color', AsapClientPipe.dictionary.mainColor);

    html.style.setProperty('--bg-navbar', AsapClientPipe.dictionary.bgNavbar);
    html.style.setProperty('--link-navbar', AsapClientPipe.dictionary.linkNavbar);

    html.style.setProperty('--bg-sidebar', AsapClientPipe.dictionary.bgSidebar);
    html.style.setProperty('--link-sidebar', AsapClientPipe.dictionary.linkSidebar);

    html.style.setProperty('--bg-footer', AsapClientPipe.dictionary.bgFooter);
    html.style.setProperty('--link-footer', AsapClientPipe.dictionary.linkFooter);

    html.style.setProperty('--bg-btn', AsapClientPipe.dictionary.bgBtn);
    html.style.setProperty('--text-btn', AsapClientPipe.dictionary.textBtn);

    html.style.setProperty('--bg-sidebar-viewer', AsapClientPipe.dictionary.bgSidebarViewer);
    html.style.setProperty('--link-sidebar-viewer', AsapClientPipe.dictionary.mainColor);
    html.style.setProperty('--bg-btn-viewer', AsapClientPipe.dictionary.bgBtnViewer);
    html.style.setProperty('--text-btn-viewer', AsapClientPipe.dictionary.textBtnViewer);
    html.style.setProperty('--text-btn-viewer-active', AsapClientPipe.dictionary.textBtnViewerActive);

    html.style.setProperty('--main-color', AsapClientPipe.dictionary.mainColor);

    html.style.setProperty('--primary-color', AsapClientPipe.dictionary.primaryColor);
    html.style.setProperty('--primary-color-hover', AsapClientPipe.dictionary.primaryColorHover);

    html.style.setProperty('--app-logo', AsapClientPipe.dictionary.logoDark);
  }

  reloadHeader() {
    console.log(this.headerComponent);
    this.headerComponent.reload();
  }

}
