import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'asapClient'
})
export class AsapClientPipe implements PipeTransform {

  public static dictionary = {
    appName: 'Portal',
    favicon: 'https://asap.tecnologiaasap.com.br/assets/img/emblem-small.png',
    clientName: 'ASAP',
    welcome: 'Bem vindo',
    intro: 'Você está na Universidade',
    influencersParagraph: 'Você também pode ser um influenciador',
    loginBG: 'https://storage.bhs.cloud.ovh.net/v1/AUTH_b9cd7ce1b3f94316bd6820419f18f7fe/asap-store/storage/images/e68df63f2fa61cabcc37bcda23b8b73b/sm',
    logoLight: 'https://storage.bhs.cloud.ovh.net/v1/AUTH_b9cd7ce1b3f94316bd6820419f18f7fe/asap-store/storage/images/267b4c02d246510546da685208058c81/sm',
    logoDark: 'https://storage.bhs.cloud.ovh.net/v1/AUTH_b9cd7ce1b3f94316bd6820419f18f7fe/asap-store/storage/images/267b4c02d246510546da685208058c81/sm',
    logoFooter: 'https://storage.bhs.cloud.ovh.net/v1/AUTH_b9cd7ce1b3f94316bd6820419f18f7fe/asap-store/storage/images/267b4c02d246510546da685208058c81/sm',
    logohelpdesk: 'https://asap.tecnologiaasap.com.br/assets/img/helpdesk.png',
    logoEAD: 'https://storage.bhs.cloud.ovh.net/v1/AUTH_b9cd7ce1b3f94316bd6820419f18f7fe/asap-store/storage/images/267b4c02d246510546da685208058c81/sm',
    emblem: 'https://asap.tecnologiaasap.com.br/assets/img/emblem.png',
    bannerHome: 'https://asap.tecnologiaasap.com.br/assets/img/banner-home.jpg',
    bannerPage: 'https://asap.tecnologiaasap.com.br/assets/img/banner-home.jpg',
    bannerVoucher: 'https://asap.tecnologiaasap.com.br/assets/img/banner-home-beneficios.jpg',
    mainColor: '#f4ac1e',
    bgClient: '#ffffff',
    bgSidebar: '#1f2123',
    linkSidebar: '#ffffff',
    bgNavbar: '#1f2123',
    linkNavbar: '#ffffff',
    bgFooter: '#1f2123',
    linkFooter: '#ffffff',
    bgBtn: '#f4ac1e',
    textBtn: '#ffffff',
    bgSidebarViewer: '#ffffff',
    bgBtnViewer: '#f4ac1e',
    textBtnViewer: '#ffffff',
    textBtnViewerActive: '#f4ac1e',
    primaryColor: '#f4ac1e',
    primaryColorHover: '#f4ac1e',
    supplementaryDataRequired: false,
    loginAD: false,
    firebase: null,
    documentsLayout: '',
    loginUniqueCodeTerm: 'Código',
    loginTermUsername: 'Email',
    loginTermPassword: 'senha',
    navbarPlataformsDisplay: true,
    grid: 'container',
    loginStyle: 'screen',
    navbarMenuItens: [],
    navbarLoginButtonText: 'Entrar',
    navbarLoginButtonColor: '#ffffff',
    navbarLoginButtonBg: '#444444',
    navbarShowMyPurchases: true,
    navbarPagesItems: []
  };

  public static setDictionary(dic) {
    for (const key in dic) {
      AsapClientPipe.dictionary[key] = dic[key];
    }
  }

  transform(value: any, args?: any): any {
    if (AsapClientPipe.dictionary[value] !== undefined) {
      return AsapClientPipe.dictionary[value];
    }
    return value;
  }

}
