import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'home-platform-change',
  templateUrl: './home-platform-change.component.html',
  styleUrls: ['./home-platform-change.component.scss']
})
export class HomePlatformChangeComponent implements OnInit {

  @Input() platform: string;

  private channel;

  public platforms = [
    {
      id: 'custom', 
      name: 'Configurar Sistema', 
      action: () => {
        if (this.platform != 'custom') {
          window.location.href = `/editor/custom`;
        }
      }
    },
    {
      id: 'trail', 
      name: 'Criar Conteúdo', 
      action: () => {
        if (this.platform != 'trail') {
          window.location.href = `/gerenciadores`;
        }
      }
    },
    {
      id: 'ead', 
      name: 'Editar Layout', 
      action: () => {
        if (this.platform != 'ead') {
          window.location.href = `/editor/ead`;
        }
      }
    },
    {
      id: 'registration', 
      name: 'Cadastrar usuários', 
      action: () => {
        if (this.platform != 'registration') {
          window.location.href = `/cadastros`;
        }
      }
    }
  ]

  constructor() {
    this.channel = new BroadcastChannel('asap-admin-channel');
  }

  ngOnInit() {
  }

}

