import { Injectable } from '@angular/core';

import { DataService } from '../services/data.service';
import { Field } from '../model/field.model';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class FieldRepository {
  constructor(public dataService: DataService) {
  }

  public collection(): Promise<Array<Field>> {
    return new Promise<Array<Field>>((resolve, reject) => {
      this.dataService.fields().subscribe((res: any) => {
        const arrayModel: Array<Field> = res.data.map((item: Field) => {
          return this.hydrate(item);
        });
        resolve(arrayModel);
      }, err => {
        reject(err);
      });
    });
  }

  public save(field: Field): Promise<Field> {
    return new Promise<Field>((resolve, reject) => {
      if (field.id) {
        this.dataService.formPut(field.id, field).subscribe((res: any) => {
          resolve(field);
        }, err => {
          reject(err);
        });
        return;
      }

      this.dataService.formPost(field).subscribe((res: any) => {
        const newField = this.hydrate(res.data);
        resolve(newField);
      }, err => {
        reject(err);
      });

    });
  }

  public remove(id: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      return this.dataService.formDelete(id).subscribe(() => {
        resolve();
      }, () => {
        reject();
      });
    });
  }

  public hydrate(data: any): Field {
    const field = new Field();

    for (const key in data) {
      field[key] = data[key];
    }

    field.width = data.width || 'col-12';
    
    if (data.memberOf && data.memberOf.length > 0) {
      field.memberOf = data.memberOf.map((item: string) => {
        return +item;
      });
    } else {
      field.memberOf = [];
    }

    return field;
  }


}
