import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-viewer-config',
  templateUrl: 'viewer-config.component.html'
})
export class ViewerConfigComponent {
  @Input() item: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  constructor(
  ) {
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  getPhoto($event: any): void {
    this.item.logoSidebarViewer = $event.path;
  }

}
