import { ThrowStmt } from '@angular/compiler';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
    selector: 'app-editor-custom-component-profile-about-config',
    templateUrl: 'profile-about-config.component.html',
    providers: [ DataService ]
})
export class ProfileAboutConfigComponent implements OnInit {
    
    @Input() item: any;
    @Input() layout: number;
    @Input() customData: any;
    @Output() public sendEvent = new EventEmitter<any>();

    public complement

    constructor(
        public service: DataService
    ) {
    }

    ngOnInit() {
        this.service.complement().subscribe((response:any) => {
            this.complement = response.data;
        });
    }

    public add() {
        this.fields().push({});
    }

    public fields() {
        if (!this.item.profileAbout) {
            this.item.profileAbout = {
                fields: []
            }
        }
        if (!this.item.profileAbout.fields) {
            this.item.profileAbout.fields = [];
        }

        return this.item.profileAbout.fields;
    }

    public modelChange(field,value) {
        let c = this.complement.find(c => c.name == value)
        if (c) {
            field.fieldLabel = c.label;
            field.data = {
                label: c.label,
                name: c.name,
                type: c.type
            };
        }
    }

    public emitSendEvent() {
        this.sendEvent.emit();
    }

}
