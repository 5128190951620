import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NouisliderModule } from 'ng2-nouislider';
import { TagInputModule } from 'ngx-chips';
import { SelectDialogComponent } from './select-dialog/select-dialog.component';
import { MaterialDesignModule } from '../material.module';
import { AsapSkeletonModule } from 'asap-skeleton';
import { AsapUploadModule } from 'asap-upload';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NouisliderModule,
    TagInputModule,
    RouterModule,
    AsapUploadModule,
    MaterialDesignModule,
    AsapSkeletonModule
  ],
  declarations: [
    SelectDialogComponent,
  ],
  exports: [
    SelectDialogComponent,
    AsapUploadModule,
    AsapSkeletonModule
  ],
  entryComponents: [
    SelectDialogComponent
  ]
})

export class SharedModule {
}
