import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-grid-config',
  templateUrl: 'grid-config.component.html'
})
export class GridConfigComponent implements OnChanges {
  @Input() item: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  constructor(
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item && this.item) {
      this.item.dashboardType = this.item.dashboardType || 'site';
    }
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

}
