import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { Field } from 'src/app/@core/model/field.model';
import { Group } from 'src/app/@core/model/group.model';

import { FieldRepository } from 'src/app/@core/repository/field.repository';
import Swal from 'sweetalert2';
import { GroupRepository } from 'src/app/@core/repository/group.repository';
import { IbgeService } from 'src/app/@core/services/ibge.service';
import { database } from 'firebase';

@Component({
  selector: 'app-editor-custom-component-supplementary-data-config',
  templateUrl: './supplementary-data-config.component.html',
  styleUrls: [  ],
  providers: [ IbgeService ]
})
export class SupplementaryDataConfigComponent implements OnInit {

  @Input() item: any;
  @Input() layout: number;
  @Input() customData: any;
  @Output() public reloadStageEvent = new EventEmitter<any>();
  @Output() public sendEvent = new EventEmitter<any>();

  public fieldToAdd: Field;

  public fieldArray: Array<Field>;
  public groupArray: Array<Group>;

  createFieldLoading: boolean;
  updateFieldLoading: boolean;

  filteredFieldArray: Array<Field>;
  groupFilterId: string;
  groupFilter: Group;

  groupSaving: boolean;

  states: any;
  cities: any;

  constructor(
    public fieldRepository: FieldRepository,
    public groupRepository: GroupRepository,
    public ibge: IbgeService
  ) {
    this.fieldToAdd = new Field();
  }

  ngOnInit() {
    this.getFields();
    this.getStates();
  }

  getStates() {
    this.ibge.getState().subscribe((response) => {
      this.states = response;
    });
  }

  getCities(uf, fieldToEmpty = null) {
    let state = this.states.find(s => s.sigla == uf);
    this.cities = [];
    if (state) {
      this.ibge.getCity(state.id).subscribe((response) => {
        this.cities = response;
      });
    }

    if (fieldToEmpty) {
      this[fieldToEmpty].valid = [];
    }
  }

  getFields() {
    this.fieldRepository.collection().then(res => {
      this.fieldArray = res;
      console.log(res)
      this.fieldFilterApply();
    });
  }

  fieldFilterApply() {
    if (!this.fieldArray) {
      return;
    }

    console.log(this.groupFilterId);

    if (!this.groupFilterId || this.groupFilterId === '0') {
      this.filteredFieldArray = this.fieldArray.filter((field: Field) => field.memberOf.length === 0);
    } else {
      this.filteredFieldArray = this.fieldArray.filter((field: Field) => field.memberOf.includes(+this.groupFilterId));
    }

  }

  save(field: Field) {
    /* 
    var aditionalFields = {
      state: {'label':'Estado','name':'Estado','type':'select','required':1,'width':'col-3'},
      city: {'label':'Cidade','name':'Cidade','type':'select','required':1,'width':'col-3'},
      neighborhood: {'label':'Bairro','name':'Bairro','type':'text','required':1,'width':'col-3'},
      street: {'label':'Rua','name':'Rua','type':'text','required':1,'width':'col-3'},
      complemento: {'label':'Complemento','name':'Complemento','type':'text','required':1,'width':'col-3'},
      numero: {'label':'Numero','name':'Numero','type':'number','required':1,'width':'col-3'},
    };
    */
   
    if (!field.id) {
      this.createFieldLoading = true;
    } else {
      this.updateFieldLoading = true;
    }

    field.memberOf = [];

    if (this.groupFilterId) {
      field.memberOf.push(+this.groupFilterId);
    }

    this.fieldRepository.save(field).then(res => {
      this.reloadStageEvent.emit();

      if (!field.id) {
        Swal.fire('Sucesso', 'Campo adicionado com sucesso', 'success');

        field.id = res.id;
        this.fieldArray.push(field);
        this.fieldFilterApply();

        this.fieldToAdd = new Field();
        this.createFieldLoading = false;
      } else {
        Swal.fire('Sucesso', 'Campo atualizado com sucesso', 'success');
        this.updateFieldLoading = false;
      }

    }, err => {
      console.log('SupplementaryDataConfigComponent: save: err', err);
    });
  }

  remove(field: Field) {
    Swal.fire({
      title: 'Confirmar exclusão',
      text: 'Você tem certeza que quer remover o campo "' + field.label + '"? Essa ação será irreverspivel!',
      icon: 'question',
      showCancelButton: true
    }).then((result) => {
      if (result.value) {
        const index = this.fieldArray.indexOf(field);
        this.fieldArray.splice(index, 1);

        this.fieldFilterApply();

        this.fieldRepository.remove(field.id)
          .then(res => {
            this.reloadStageEvent.emit();
            console.log(res);
          });
      }
    });

  }

  setAlign(el: any, align: string) {
    this.groupFilter.value[el] = align;
  }

  saveGroup(group: Group) {
    this.groupSaving = true;
    this.groupRepository.save(group).then(res => {
      Swal.fire('Grupo salvo com sucesso!');
      this.groupSaving = false;
    });
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  configByType(type) {
    if (type == 'text-fixed') {
      this.fieldToAdd.options = {
        agreeCheckbox: true,
        agreeText: 'Você aceita os termos?'
      };
    }
  }

  selectRestriction(option, fieldName) {
    let field = null;
    console.log("[selectRestriction]", fieldName)
    if (typeof fieldName == 'string') {
      field = this[fieldName];
    }
    else {
      field = fieldName;
    }
    field.valid = field.valid || [];

    const idx = field.valid.indexOf(option);

    if (idx >= 0) {
      field.valid.splice(idx, 1);
    }
    else {
      field.valid.push(option);
    }

    console.log("[selectRestriction]", fieldName, field);
  }

}
