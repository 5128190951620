import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class DataService {
  constructor(public http: HttpClient) {
  }

  fields() {
    return this.http.get(environment.baseUrl + '/api/v2/admin/user/form/listAll', this.header);
  }

  groups() {
    return this.http.get(environment.baseUrl + '/api/v2/admin/group/cad', this.header);
  }

  groupPut(id: number, data: any) {
    return this.http.put(environment.baseUrl + '/api/v2/admin/group/cad/' + id, data, this.header);
  }

  formPost(data: any) {
    return this.http.post(environment.baseUrl + '/api/v2/admin/form', data, this.header);
  }

  formPut(id: number, data: any): Observable<any> {
    return this.http.put(environment.baseUrl + '/api/v2/admin/form/' + id, data, this.header);
  }

  formDelete(id: number) {
    return this.http.delete(environment.baseUrl + '/api/v2/admin/form/' + id + '/delete', this.header);
  }

  layout() {
    // https://api.tecnologiaasap.com.br/api/v2/admin/color/108?client_id=108&client_id=108
    return this.http.get(environment.baseUrl + '/api/v2/client/type/layout', this.header);
  }

  complement() {
    return this.http.get(environment.baseUrl + '/api/v2/admin/user/form', this.header);
  }

  get header(): {headers: HttpHeaders} {
    const token = sessionStorage.getItem('token');
    const headerOptions = {
      Authorization: token,
      Accept: 'application/json'
    };
    return {headers: new HttpHeaders(headerOptions)};
  }

}
