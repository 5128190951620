import { DataService } from '../services/data.service';
import { Group, GroupValue } from '../model/group.model';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class GroupRepository {
  constructor(public dataService: DataService) {
  }

  public collection(): Promise<Array<Group>> {
    return new Promise<Array<Group>>((resolve, reject) => {
      this.dataService.groups().subscribe((res: any) => {
        let arrayModel: Array<Group>;
        arrayModel = res.data.map((item: any) => {
          console.log('GroupRepository: collection: item', item);
          return this.hydrate(item);
        });
        resolve(arrayModel);
      }, err => {
        reject(err);
      });
    });
  }

  public save(group: Group): Promise<Group> {
    if (group.id) {
      return new Promise<Group>((resolve, reject) => {
        this.dataService.groupPut(group.id, group).subscribe((res: any) => {
          resolve(this.hydrate(res.data));
        }, err => {
          reject(err);
        });
      });
    }
  }


  hydrate(data: any): Group {
    const group = new Group();
    group.id = data.id;
    group.name = data.name;
    group.type = data.type;
    group.complement = data.complement;
    group.value = this.valueHydrate(data.value);

    return group;
  }

  valueHydrate(value: any): GroupValue {
    const groupValue = new GroupValue();

    if (!value) {
      value = {};
    }

    groupValue.titleText = value.titleText || 'Dados complementares';
    groupValue.titleColor = value.titleColor || '#222';
    groupValue.titleSize = value.titleSize || 32;
    groupValue.titleAlign = value.titleAlign || 'center';
    groupValue.subtitleText = value.subtitleText || 'Complete o seu cadastro com os dados complementares do seu acesso';
    groupValue.subtitleColor = value.subtitleColor || '#BBB';
    groupValue.subtitleSize = value.subtitleSize || 18;
    groupValue.subtitleAlign = value.subtitleAlign || 'center';

    return groupValue;
  }

}
