import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-chat-config',
  templateUrl: 'chat-config.component.html'
})
export class ChatConfigComponent {
  @Input() item: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();
  
  public chat: any;

  constructor(
  ) {
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

}
