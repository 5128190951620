import { Component, Input, Output, EventEmitter, ViewContainerRef, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Group } from 'src/app/@core/model/group.model';

@Component({
  selector: 'app-col-right',
  templateUrl: './col-right.component.html'
})

export class ColRightComponent {
  @Input() item: any;
  @Input() section: any;
  @Input() layoutComponentSelected: any;
  @Input() settings: any;
  @Input() customData: any;
  @Input() layout: any;

  @Output() public sendEvent = new EventEmitter<any>();
  @Output() public reloadHeaderEvent = new EventEmitter<any>();
  @Output() public saveConfigEvent = new EventEmitter<any>();
  @Output() public reloadStageEvent = new EventEmitter<any>();

  @ViewChild('supplementaryDataConfig', { static: false }) supplementaryDataConfig: any;

  constructor(
  ) {

  }

  setAlign(element: any, align: string) {
    element.align = align;
  }

  setElementAlign(element: any, align: string) {
    element = align;
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  reloadStage() {
    this.reloadStageEvent.emit();
  }

  reloadHeader() {
    this.reloadHeaderEvent.emit();
  }

  saveConfig() {
    this.saveConfigEvent.emit();
  }

  getVideoArchive($event: { path: string }) {
    this.item.source_path = $event.path;
  }

  getPhoto($event: { path: string }) {
    this.item.url = $event.path;
  }

  filterByGroupApply(group: Group) {
    if (!group) {
      this.supplementaryDataConfig.groupFilter = null;
      this.supplementaryDataConfig.groupFilterId = '0';
      this.supplementaryDataConfig.fieldFilterApply();
      return;
    }

    this.supplementaryDataConfig.groupFilter = group;
    this.supplementaryDataConfig.groupFilterId = group.id;
    this.supplementaryDataConfig.fieldFilterApply();
  }
}
