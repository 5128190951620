import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-custom-header',
  template: `
      <h3 class="component-title ls-5 fw-600" [ngStyle]="{
      'text-align': setting.title.align,
      'font-size.px': setting.title.size,
      'color': setting.title.color
    }">{{ setting.title.text }}</h3>

    <p class="component-subtitle mb-4" [ngStyle]="{
      'text-align': setting.subtitle.align,
      'font-size.px': setting.subtitle.size,
      'color': setting.subtitle.color}">
      {{ setting.subtitle.text }}
    </p>
  `
})
export class CustomHeaderComponent {
  @Input() setting: any;
}
