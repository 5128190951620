import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';


@Component({
  selector: 'app-col-left',
  templateUrl: './col-left.component.html'
})

export class ColLeftComponent implements OnInit {

  @Input() public layout: number;
  @Input() public section: string;

  @Output() public insertItemEvent = new EventEmitter<any>();
  @Output() public sectionChanged = new EventEmitter<any>();
  @Output() public selectLayoutItemEvent = new EventEmitter<any>();

  layoutComponentSelected: any;

  loggedUser: any;

  layoutComponentArray = [
    {
      component: 'info',
      label: 'Informações',
      icon: 'info'
    },
    {
      component: 'fonts',
      label: 'Fontes',
      icon: 'fonts'
    },
    {
      component: 'text',
      label: 'Textos',
      icon: 'justify-left'
    },
    {
      component: 'logo',
      label: 'Login',
      icon: 'person'
    },
    {
      component: 'navbar',
      label: 'Menu Superior',
      icon: 'layout-sidebar-inset'
    },
    {
      component: 'cor',
      label: 'Cores',
      icon: 'palette'
    },
    {
      component: 'footer',
      label: 'Rodapé',
      icon: 'layout-sidebar-inset-reverse'
    },
    {
      component: 'sidebar',
      label: 'Menu Lateral',
      icon: 'layout-sidebar'
    },
    {
      component: 'portal',
      label: 'Portal',
      icon: 'layout-text-window-reverse'
    },
    {
      component: 'viewer',
      label: 'Visualizador de cursos',
      icon: 'book'
    },
    {
      component: 'pre-viewer',
      label: 'Pré visualizadores',
      icon: 'book-half'
    },
    {
      component: 'supplementary-data',
      label: 'Dados complementares',
      icon: 'hdd'
    },
    {
      component: 'grid',
      label: 'Grid',
      icon: 'grid-3x3'
    },
    {
      component: 'chat',
      label: 'Chat',
      icon: 'chat'
    },
    {
      component: 'terms',
      label: 'Termos',
      icon: 'file-text'
    },
    {
      component: 'profile-about',
      label: 'Perfil',
      icon: 'person-badge'
    },
    {
      component: 'helpdesk-page',
      label: 'Helpdesk',
      icon: 'headphones'
    },
  ];

  ngOnInit() {
    this.getLoggedUser();
  }

  getLoggedUser() {
    const lu = JSON.parse(sessionStorage.getItem('loggedUser'));
    this.loggedUser = lu.data;
  }


  setSection(section: string) {
    this.sectionChanged.emit(section);
  }

  insertItem(type: string) {
    this.insertItemEvent.emit(type);
  }

  selectLayoutItem(item: string): void {
    this.layoutComponentSelected = item;
    this.selectLayoutItemEvent.emit(item);
  }

}
