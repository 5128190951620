import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ClientService } from 'src/app/@core/services/client.service';

@Component({
  selector: 'app-editor-custom-component-navbar',
  templateUrl: 'navbar.component.html',
  styleUrls: [ './navbar.component.scss' ]
})
export class NavbarComponent implements OnInit {
  @Input() item: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  pageListBuffer: any;
  pageList: any;

  showNavbar: boolean;

  text = {
    eventos: 'Eventos',
    portal: 'Portal',
    elearning: 'Escola',
    ecommerce: 'Loja'
  };

  icon = {
    eventos: 'date_range',
    portal: 'school',
    elearning: 'school',
    ecommerce: 'local_grocery_store'
  };

  sectionLabel = {
    news: 'Notícias',
    trail: 'Cursos'
  };

  constructor(
    public clientService: ClientService
  ) {
  }

  ngOnInit() {
    this.getPages();
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  getPhoto($event: any): void {
    this.item.logoEAD = $event.path;
  }

  getPages() {
    this.clientService.allPages().subscribe(res => {
      this.pageListBuffer = res.data;
      setInterval(() => {
        this.pageList = [];

        this.pageListBuffer.map((section: any) => {
          section.pagesActive = section.pages.filter((page: any) => {
            this.item.navbarPagesItems = this.item.navbarPagesItems.filter(item => item != null);
            for (const item of this.item.navbarPagesItems) {
              if (+page.id === +item) {
                return true;
              }
            }
            return false;
          });
          this.pageList.push(section);
        });

      }, 500);

      this.showNavbar = true;
    });
  }

  getTitle(menuItem: any) {
    if (menuItem.type === 'external_link' || menuItem.type === 'internal_link') {
      return menuItem.label;
    }

    if (menuItem.type === 'section') {
      const sections = {
        news: 'Notícias',
        trail: 'Cursos'
      };
      return sections[menuItem.section];
    }

    if (menuItem.type === 'page') {
      const find = this.pageList.find((item: any) => +item.id === +menuItem.page);
      if (find) {
        return find.name;
      }
    }

  }

  getPage(page: number) {
    const find = this.pageList.find((item: any) => +item.id === +page);
    return find;
  }

}
