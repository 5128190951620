import { Component, Input, OnInit } from '@angular/core';
import { ClientService } from 'src/app/@core/services/client.service';
import { DataService } from 'src/app/@core/services/data.service';

@Component({
  selector: 'app-editor-custom-component-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  @Input() item: any;
  layout: any;

  pageListBuffer: any;
  pageList: any;

  constructor(
    public dataService: DataService,
    public clientService: ClientService
  ) {
  }

  ngOnInit() {
    this.getLayout();
    this.getPages();
  }

  getLayout() {
    this.dataService.layout()
      .subscribe((res: any) => {
        this.layout = res.data.value;
      });
  }

  getPages() {
    this.clientService.allPages().subscribe(res => {
      this.pageListBuffer = res.data;
      setInterval(() => {
        this.pageList = [];

        this.pageListBuffer.map((section: any) => {
          section.pagesActive = section.pages.filter((page: any) => {
            for (const item of this.item.footerPagesItems) {
              if (+page.id === +item) {
                return true;
              }
            }
            return false;
          });
          this.pageList.push(section);
        });

      }, 500);
    });
  }


}
