import { Component, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-top-menu',
    templateUrl: './top-menu.component.html'
})

export class TopMenuComponent  {
  @Output() public saveEvent = new EventEmitter<any>();

  closeWindow() {
    if (window.location.href.includes('admin.')) {
      window.location.href = '/configurar';
    }
    else {
      window.close();
    }
  }

  save() {
    this.saveEvent.emit();
  }
}
