import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { NouisliderModule } from 'ng2-nouislider';
import { TagInputModule } from 'ngx-chips';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxMaskModule } from 'ngx-mask';

import { InfoComponent } from './info/info.component';
import { InfoConfigComponent } from './info/config/info-config.component';

import { FontsComponent } from './fonts/fonts.component';
import { FontsConfigComponent } from './fonts/config/fonts-config.component';

import { LogoComponent } from './logo/logo.component';
import { LogoConfigComponent } from './logo/config/logo-config.component';

import { CorComponent } from './cor/cor.component';
import { CorConfigComponent } from './cor/config/cor-config.component';

import { PortalComponent } from './portal/portal.component';
import { PortalConfigComponent } from './portal/config/portal-config.component';

import { ViewerComponent } from './viewer/viewer.component';
import { ViewerConfigComponent } from './viewer/config/viewer-config.component';

import { PreViewerComponent } from './pre-viewer/pre-viewer.component';
import { PreViewerConfigComponent } from './pre-viewer/config/pre-viewer-config.component';

import { TextComponent } from './text/text.component';
import { TextConfigComponent } from './text/config/text-config.component';


import { MaterialDesignModule } from '../material.module';
import { SharedModule } from '../shared/shared.module';
import { PipeModule } from '../@core/pipes/pipe.module';
import { CustomHeaderComponent } from './custom-header/custom-header.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AsapLimitToModule } from 'asap-limit-to';
import { AsapSkeletonModule } from 'asap-skeleton';

import { ComponentTitleComponent } from './component-title/component-title.component';
import { SectionTitleComponent } from './section-title/section-title.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NavbarConfigComponent } from './navbar/config/navbar-config.component';
import { SupplementaryDataConfigComponent } from './supplementary-data/config/supplementary-data-config.component';
import { SupplementaryDataComponent } from './supplementary-data/supplementary-data.component';
import { FooterComponent } from './footer/footer.component';
import { FooterConfigComponent } from './footer/config/footer-config.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SidebarConfigComponent } from './sidebar/config/sidebar-config.component';
import { GridComponent } from './grid/grid.component';
import { GridConfigComponent } from './grid/config/grid-config.component';
import { ChatComponent } from './chat/chat.component';
import { ChatConfigComponent } from './chat/config/chat-config.component';
import { SortablejsModule } from 'ngx-sortablejs';
import { TermsComponent } from './terms/terms.component';
import { TermsConfigComponent } from './terms/config/terms-config.component';
import { ProfileAboutComponent } from './profile-about/profile-about.component';
import { ProfileAboutConfigComponent } from './profile-about/config/profile-about-config.component';
import { SelectableComponent } from './selectable/selectable.component';
import { AsapCrudModule } from 'asap-crud';
import { HelpdeskConfigComponent } from './helpdesk/config/helpdesk-config.component';
import { HelpdeskComponent } from './helpdesk/helpdesk.component';

const components = [
  SelectableComponent,
  InfoComponent,
  InfoConfigComponent,
  FontsComponent,
  FontsConfigComponent,
  LogoComponent,
  LogoConfigComponent,
  CorComponent,
  CorConfigComponent,
  ViewerComponent,
  ViewerConfigComponent,
  PreViewerComponent,
  PreViewerConfigComponent,
  TextComponent,
  TextConfigComponent,
  CustomHeaderComponent,
  ComponentTitleComponent,
  SectionTitleComponent,
  PortalConfigComponent,
  PortalComponent,
  NavbarComponent,
  NavbarConfigComponent,
  SupplementaryDataComponent,
  SupplementaryDataConfigComponent,
  FooterComponent,
  FooterConfigComponent,
  SidebarComponent,
  SidebarConfigComponent,
  GridComponent,
  GridConfigComponent,
  ChatComponent,
  ChatConfigComponent,
  TermsComponent,
  TermsConfigComponent,
  ProfileAboutComponent,
  ProfileAboutConfigComponent,
  HelpdeskComponent,
  HelpdeskConfigComponent
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NouisliderModule,
    ReactiveFormsModule,
    TagInputModule,
    ColorPickerModule,
    CarouselModule,
    MaterialDesignModule,
    SharedModule,
    PipeModule,
    AsapLimitToModule,
    AsapSkeletonModule,
    SortablejsModule,
    NgxMaskModule.forRoot(),
    AsapCrudModule
  ],
  declarations: [
    ...components
  ],
  exports: [
    ...components,
    CarouselModule
  ]
})

export class ComponentsModule { }
