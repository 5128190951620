import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: 'asap-selectable',
    templateUrl: './selectable.component.html',
    styleUrls: [ './selectable.component.scss' ]
})
export class SelectableComponent implements OnInit {

    @Input() list;
    @Input() active;
    @Input() index;
    
    ngOnInit(): void {
        
    }

    moveUp(index) {
        if (index > 0) {
            let item = this.list[index];
            this.list[index] = this.list[index-1];
            this.list[index-1] = item;
        }
    }

    moveDown(index) {
        if (index < this.list.length-1) {
            let item = this.list[index];
            this.list[index] = this.list[index+1];
            this.list[index+1] = item;
        }
    }

    delete(index) {
        this.list.splice(index,1);
    }

}