import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-fonts',
  templateUrl: 'fonts.component.html'
})
export class FontsComponent {
    @Input() item: any;
    @Input() layout: number;
}
