import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { AppComponent } from './app.component';
import { StructureModule } from './structure/structure.module';
import { ComponentsModule } from './components/components.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { RedirectComponent } from './redirect/redirect.component';
import { HomeComponent } from './home/home.component';
import { HomePlatformChangeComponent } from './home/home-platform-change/home-platform-change.component';
import { AsapCrudModule } from 'asap-crud';
import { SharedModule } from './shared/shared.module';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { NgxMaskModule } from 'ngx-mask';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { AngularFireModule } from '@angular/fire';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

registerLocaleData(localePt, 'pt-BR');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  declarations: [
    AppComponent,
    RedirectComponent,
    HomeComponent,
    HomePlatformChangeComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    StructureModule,
    ComponentsModule,
    BrowserAnimationsModule,
    AsapCrudModule,
    CarouselModule,
    SharedModule,
    RouterModule.forRoot([
      {
        path: 'redirect',
        component: RedirectComponent
      },
      {
        path: '',
        component: HomeComponent
      }
    ]),
    SimpleNotificationsModule.forRoot({
        timeOut: 3000,
        showProgressBar: false,
        // theClass:'mt-10',
        pauseOnHover: true,
        clickToClose: true,
        position: ['top', 'right']
    }),
    AngularFireModule.initializeApp({
      apiKey: 'AIzaSyCnQ7hg9qn8mrS3zSLX-xeXX3wKbuC2GXA',
      authDomain: 'admin-17e4f.firebaseapp.com',
      databaseURL: 'https://admin-17e4f.firebaseio.com',
      projectId: 'admin-17e4f',
      storageBucket: 'admin-17e4f.appspot.com',
      messagingSenderId: '572223664609'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  
    AsapCrudModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
