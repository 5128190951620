import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-sidebar-config',
  templateUrl: 'sidebar-config.component.html'
})
export class SidebarConfigComponent {
  @Input() item: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  colorToAdd: string;

  constructor(
  ) {
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  getPhotoWithTarget(target: any, $event: any): void {
    this.item[target] = $event.path;
  }

  setAlign(element: any, align: string): void {
    element.align = align;
  }

  addColorToPallet() {
    if (this.colorToAdd === '') {
      return;
    }

    if (!this.item.myColors) {
      this.item.myColors = [];
    }
    this.item.myColors.push(this.colorToAdd);
    this.colorToAdd = '';
  }

  removeColor(index: number) {
    this.item.myColors.splice(index, 1);
  }

}
