import { Component, Input, Output, EventEmitter, OnInit, Renderer2 } from '@angular/core';
import { FieldRepository } from 'src/app/@core/repository/field.repository';
import { Field } from 'src/app/@core/model/field.model';

@Component({
  selector: 'app-editor-custom-component-logo',
  templateUrl: 'logo.component.html'
})
export class LogoComponent implements OnInit {
  @Input() item: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  showAD: boolean;
  showUserPass: boolean;

  hide = true;
  formType: any;
  effectBlur = true;
  effectOverlay = true;

  constructor(
    private render: Renderer2,
  ) {
    console.log(this.formType);
  }

  ngOnInit() {
    if (this.item.loginAD) {
      this.showAD = true;
    } else {
      this.showUserPass = true;
    }
    this.formType = 'isLogin';

    this.item.loginMobileLogo = this.item.hasOwnProperty('loginMobileLogo') ? this.item.loginMobileLogo : this.item.logoLight;
    this.item.loginMobileBG = this.item.hasOwnProperty('loginMobileBG') ? this.item.loginMobileBG : this.item.loginBG;
    this.item.loginMobileIntroText = this.item.hasOwnProperty('loginMobileIntroText') ? this.item.loginMobileIntroText : this.item.loginTextHighlight;
    this.item.loginMobileButtonText = this.item.hasOwnProperty('loginMobileButtonText') ? this.item.loginMobileButtonText : 'Entrar';

  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  getPhoto(target: any, $event: any): void {
    if (target === 'logoLight') {
      this.item.logoLight = $event.path;
    }
    if (target === 'logoDark') {
      this.item.logoDark = $event.path;
    }
    if (target === 'logoFooter') {
      this.item.logoFooter = $event.path;
    }
    if (target === 'logoEAD') {
      this.item.logoEAD = $event.path;
    }
    if (target === 'logoHelpdesk') {
      this.item.logoHelpdesk = $event.path;
    }
    if (target === 'emblem') {
      this.item.emblem = $event.path;
    }

  }
}
