import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-editor-custom-component-pre-viewer-config',
  templateUrl: 'pre-viewer-config.component.html'
})
export class PreViewerConfigComponent {
  @Input() item: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  preViewerStyle: string;

  preViewerStyleChannels: string

  preViewerType: any;

  constructor(
  ) {
  }

  ngOnInit () {

    if (typeof this.item.preViewerType === 'undefined') {
      this.item.preViewerType = 'courses';
    }

    if (typeof this.item.preViewerStyle === 'undefined') {
      this.item.preViewerStyle = 'card';
    }

    if (typeof this.item.preViewerStyleChannels === 'undefined') {
      this.item.preViewerStyleChannels = 'template 1';
    }

    
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  getPhoto($event: any): void {
    this.item.logoSidebarViewer = $event.path;
  }

}
