import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-custom-section-title',
  templateUrl: 'section-title.component.html'
})
export class SectionTitleComponent {
  @Input() setting: any;
  @Input() title: string;
}
