export class Group {
  id: number;
  name: string;
  type: string;
  complement: number;
  value: GroupValue;
}

export class GroupValue {
  titleText: string;
  titleColor: string;
  titleSize: number;
  titleAlign: string;
  subtitleText: string;
  subtitleColor: string;
  subtitleSize: number;
  subtitleAlign: string;
}
