import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';

import { Field } from 'src/app/@core/model/field.model';
import { Group } from 'src/app/@core/model/group.model';

import { GroupRepository } from 'src/app/@core/repository/group.repository';
import { FieldRepository } from 'src/app/@core/repository/field.repository';
import * as cep from 'cep-promise';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-editor-custom-component-supplementary-data',
  templateUrl: 'supplementary-data.component.html'
})
export class SupplementaryDataComponent implements OnInit {
  @Input() item: any;
  @Input() layout: number;

  public fieldArray: Array<Field>;
  public addressFields;

  public filteredFieldArray: Array<Field>;
  public groupFilter: Group;
  public groupFilterId: string;

  cep: string;
  cepBuffer: string;
  address: any;

  constructor(
    public fieldRepository: FieldRepository,
    public groupRepository: GroupRepository
  ) {
    this.groupFilterId = '0';
    this.initialAddress();
  }

  initialAddress() {
    this.address = {
      state: '',
      city: '',
      neighborhood: '',
      street: '',
      complemento: '',
      numero: ''
    };
  }

  ngOnInit() {
    this.getFields();
  }

  reloadFields() {
    this.fieldArray = null;
    this.filteredFieldArray = null;
    this.getFields();
  }

  getFields() {
    this.fieldRepository.collection().then(res => {
      this.fieldArray = res;
      this.fieldFilterApply();
    });
  }

  fieldFilterApply() {
    if (!this.groupFilterId || this.groupFilterId === '0') {
      this.filteredFieldArray = this.fieldArray.filter((field: Field) => {
        return field.memberOf.length === 0;
      });
      return;
    }

    this.filteredFieldArray = this.fieldArray.filter((field: Field) => {
      return field.memberOf.includes(+this.groupFilterId);
    });
  }

  fieldFilterRemove() {
    this.groupFilter = null;
    this.filteredFieldArray = this.fieldArray;
  }

  searchCep(value: string) {
    console.log(value, value.length, this.cepBuffer);
    if (value.length === 8) {
      if (value !== this.cepBuffer) {
        this.cepBuffer = value;
        cep(value).then((item) => {
          this.address = item;
        }, err => {
          this.initialAddress();
          Swal.fire('CEP não encontrado!', 'Verifique e tente novamente', 'error');
        });
      }
    }
  }

  get hasAddress(): boolean {
    return this.filteredFieldArray.some(item => item.type === 'address');
  }

}


