import { Component, Input, Output, EventEmitter, OnInit, Renderer2 } from '@angular/core';
import { FieldRepository } from 'src/app/@core/repository/field.repository';
import { Field } from 'src/app/@core/model/field.model';

@Component({
  selector: 'app-editor-custom-component-logo-config',
  templateUrl: 'logo-config.component.html'
})
export class LogoConfigComponent implements OnInit {
  @Input() item: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  fieldArray: Array<Field>;
  loggedUser: any;

  constructor(
    private render: Renderer2,
    public fieldRepository: FieldRepository,
  ) {
  }

  ngOnInit() {
    this.getFields();
    this.getLoggedUser();
  }

  getFields() {
    this.fieldRepository.collection()
      .then(res => {
        this.fieldArray = res.filter((field: Field) => {
          return field.memberOf.length === 0;
        });
      });
  }

  getLoggedUser() {
    const lu = JSON.parse(sessionStorage.getItem('loggedUser'));
    this.loggedUser = lu.data;
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  uploadSubmited($event: any): void {
    this.item.url = ($event.data) ? $event.data.url : $event.url;
  }

  getPhotoWithTarget(target: any, $event: any): void {
    this.item[target] = $event.path;
  }

  setAlign(element: any, align: string): void {
    element.align = align;
  }

}
