import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable()
export class IbgeService {

  private host = 'https://servicodados.ibge.gov.br/api/v1/localidades/estados';
  protected token = sessionStorage.getItem('token');
  protected headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Accept': 'application/json'
  };

  protected options = {
    headers: this.headers
  };

  constructor(public http: HttpClient) {}

  getState() {
    return this.http.get(this.host, this.options);
  }

  getCity(id) {
    return this.http.get(this.host + '/' + id + '/municipios', this.options);
  }
}
