import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ClientService } from 'src/app/@core/services/client.service';

@Component({
  selector: 'app-editor-custom-component-footer-config',
  templateUrl: 'footer-config.component.html'
})
export class FooterConfigComponent implements OnInit {
  @Input() item: any;
  @Input() customData: any;
  @Output() public sendEvent = new EventEmitter<any>();

  pageList: any;

  constructor(
    public clientService: ClientService
  ) {
  }

  ngOnInit() {
    this.getPages();
  }

  emitSendEvent() {
    this.sendEvent.emit();
  }

  getPhotoWithTarget(target: any, $event: any): void {
    this.item[target] = $event.path;
  }

  setAlign(element: any, align: string): void {
    element.align = align;
  }

  getPages() {
    this.clientService.allPages().subscribe(res => {
      this.pageList = res.data;
    });
  }

  getPhoto(target: any, $event: any): void {
    if (target === 'logoLight') {
      this.item.logoLight = $event.path;
    }
    if (target === 'logoDark') {
      this.item.logoDark = $event.path;
    }
    if (target === 'logoFooter') {
      this.item.logoFooter = $event.path;
    }
    if (target === 'logoEAD') {
      this.item.logoEAD = $event.path;
    }
    if (target === 'logoHelpdesk') {
      this.item.logoHelpdesk = $event.path;
    }
    if (target === 'emblem') {
      this.item.emblem = $event.path;
    }

  }

}
